import React, { useEffect } from 'react';
import { SAMLLogin } from '../../actions/authActions';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const SAML = ({ SAMLLogin, auth, history }) => {
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    const language = new URLSearchParams(location.search).get('language');

    useEffect(() => {
        SAMLLogin({ token, language });
    }, []);

    useEffect(() => {
        history.push('/');
    }, [auth, history]);

    return <></>;
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToState = {
    SAMLLogin,
};

export default connect(mapStateToProps, mapDispatchToState)(SAML);
