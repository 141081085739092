import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Dialog from '../Dialog';

import { createIdea, updateIdea, getMessagesByIssuesIds, createIssuesWithMessages } from '../../../api/multiplication';
import { getGeneratedNamesForIdea, getGeneratedBenefits, getGeneratedChallenges, getGeneratedImage } from '../../../api/ai';
import { uploadImage } from '../../../api/uploads';
import _ from 'lodash';

import MultiplicationModalHeader from './MultiplicationModalHeader';
import MultiplicationModalTabs from './MultiplicationModalTabs';
import MultiplicationModalFooter from './MultiplicationModalFooter';
import ErrorMessageModal from '../../../components/dialogs/ErrorMessageModal';
import { getTranslation } from '../../../helpers/getLanguage';
import WarningNotTokensModal from '../../ComponentsDashboard/WarningNotTokensModal';
import BuyMoreAICreditsModal from '../BuyMoreAICreditsModal';
import CheckoutModal from '../CheckoutModal';
import { setGeneratedOpenAIRequests } from '../../../actions/projectActions';
import './styles.css';

const IDEAS_IDENTIFICATION = {
    EMPTY_IDEA: 0,
    IN_PROGRESS_IDEA: 1,
    NOT_FEASIBLE_IDEA: 2,
    COMPLETED_IDEA: 3,
};

const initialState = {
    existingMultiplication: null,
    isIdeaNew: null,
    isIdeaFeasible: null,
    score: null,
    feasibleScore: null,
    potentialBenefitsOfMultiplication: [{ name: '', description: '', target: [], userInfo: '' }],
    issues: [{ comment: '', description: '', createdByUser: '' }],
    issuesMessages: [],
    ideaName: '',
    removeBenefit: '',
    comment: '',
    image: { name: '', originalName: '', id: '' },
    imagesLibrary: []
};

const initialPaymentInfo = {
    intervalCount: 1,
    quantity: 1,
    key: '',
    interval: null,
    openAIRequests: null,
    total: null,
    promoCode: '',
    promoCodeOpenAI: '',
};
const initialUpload = {
    progress: 100,
    cancel: null,
    name: '',
    date: '',
    size: 0,
    uploaded: false,
    uploadName: '',
};

const isDefaultCategory = (value) => ['Consumer', 'Company', 'Other'].includes(value);

const MultiplicationEditModal = ({
    closeDialog,
    edit,
    multipliedComponent,
    productId,
    project,
    multiplicationsMatrix,
    activeAttribute,
    teamId,
    categories = [],
    productType,
    productName,
    language,
    history,
    setGeneratedOpenAIRequests,
    ...props
}) => {
    const [multiplication, setMultiplication] = useState(_.cloneDeep(initialState));
    const [currentTab, setCurrentTab] = useState(0);
    const [isNew, setIsNew] = useState(!edit);
    const [multiplicationId, setMultiplicationId] = useState(multiplicationsMatrix.id);
    const [savedMultiplication, setSavedMultiplication] = useState(_.cloneDeep(initialState));
    const [isPending, setIsPending] = useState({ create: false, update: false });
    const [uploadInfo, setUploadInfo] = useState({ ...initialUpload });
    const [isClosing, setIsClosing] = useState(false);
    const [customCategories, setCustomCategories] = useState([...categories]);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [isCompletedIdea, setIsCompletedIdea] = useState(false);
    const [messages, setMessages] = useState([]);
    const [suggestedLoading, setSuggestedLoading] = useState(false);
    const [error, setError] = useState({
        openErrorModal: false,
        message: '',
        headerError: ''
    });
    const [suggestedOptions, setSuggestedOptions] = useState([]);
    const [selectedGenerateOptions, setSelectedGenerateOptions] = useState(new Set());
    const [openGenerateModal, setOpenGenerateModal] = useState(false); 
    const [isOpenGenerateImageModal, setIsOpenGenerateImageModal] = useState(false);
    const [generatedImage, setGeneratedImage] = useState('');
    const [potentialGeneratedImageFile, setPotentialGeneratedImageFile] = useState(null)
    const [potentialGeneratedImageUrl, setPotentialGeneratedImageUrl] = useState("")
    const [newGeneratedImage, setNewGeneratedImage] = useState({
        isNew: false,
        urlImage: ''
    });   
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
    const [isOpenBuyCreditsgModal, setIsOpenBuyCreditsgModal] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(initialPaymentInfo);
    const [isEnoghTokensForImage, setIsEnoghTokensForImage] = useState(true);

    const { name: projectName } = project.product;

    const disabledNextButton = (currentTab === 1) && (multiplication.score === null || multiplication.score === undefined);

    useEffect(() => {
        (async () => {
            let componentExists = true;
            const issuesParams = multiplicationsMatrix.issues
                ? multiplicationsMatrix.issues.map((issue) => issue.id)
                : [];
            const params = { issues: issuesParams };

            const onSuccess = (response) => {
                if (componentExists) setMessages((prev) => [...prev, ...response]);
            };

            const onError = (error) => {
                console.error(error);
                handleClose(true, { ...isPending, update: false });
            };

            await getMessagesByIssuesIds(params, onSuccess, onError);

            return () => (componentExists = false);
        })();
    }, []);

    useEffect(() => {
        if (edit) updateValues();
    }, [multiplicationsMatrix, edit, messages]);

    useEffect(() => {
        if (!isClosing) {
            const isUpToDate = savedMultiplication.image.name === multiplication.image.name;
            if (!isUpToDate) {
                handleSave();
            }
        }
    }, [multiplication.image, isClosing, savedMultiplication]);

    useEffect(() => {
        if (multiplication && multiplication.score && multiplication.feasibleScore) {
            setIsCompletedIdea(true);
        } else {
            setIsCompletedIdea(false);
        }
    }, [multiplication.score, multiplication.feasibleScore]);

    const getStatus = () => {
        const currentMultiplication = getMultiplication();
        const { 
            existingMultiplication, 
            isIdeaNew, 
            isIdeaFeasible, 
            score,
            createsBenefits,
            feasibleScore,
            issues,
            potentialBenefitsOfMultiplication,
            comment,
            ideaName,
            image
        } = currentMultiplication;

        if (isIdeaNew === false 
            || existingMultiplication === true 
            || isIdeaFeasible === false 
            || score === 0
            || feasibleScore === 0
        ) {
            return IDEAS_IDENTIFICATION.NOT_FEASIBLE_IDEA;
        };

        if (isIdeaFeasible && score > 0) {
            return IDEAS_IDENTIFICATION.COMPLETED_IDEA;
        };

        if (!createsBenefits 
            && !isIdeaFeasible 
            && score === null 
            && feasibleScore === null 
            && (issues.length === 1 || issues.length === 0)
            && !issues[0]?.comment.trim()
            && potentialBenefitsOfMultiplication.length === 1
            && !potentialBenefitsOfMultiplication[0].name.trim()
            && !comment.trim()
            && !ideaName.trim()
            && !image.id 
        ) {
            return IDEAS_IDENTIFICATION.EMPTY_IDEA;
        };

        return IDEAS_IDENTIFICATION.IN_PROGRESS_IDEA;
    };

    const getMultiplicationDetails = () => {
        const currentMultiplication = getMultiplication();

        return {
            multiplicationName: currentMultiplication.ideaName,
            potentialBenefitsOfMultiplication: currentMultiplication.potentialBenefitsOfMultiplication.filter(
                (el) => el.name
            ),
            typeMultiplication: currentMultiplication.isIdeaFeasible,
            issues: currentMultiplication.issues.filter((issue) => issue),
            comments: currentMultiplication.comment,
            removeBenefit: currentMultiplication.removeBenefit,
            rating: currentMultiplication.score,
            feasibleRating: currentMultiplication.feasibleScore,
            image: currentMultiplication.image,
            imagesLibrary: currentMultiplication.imagesLibrary,
            status: getStatus(),
            existingMultiplication: currentMultiplication.existingMultiplication,
        };
    };

    const getIssuesWithMessages = () => {
        const currentMultiplication = getMultiplication();
        const deletedIssues = currentMultiplication.deletedIssues ?? [];

        const getMessagesByIssueIdWithDeletedMessages = (issueId, issueIndex) => {
            const filteredMessages = currentMultiplication.issuesMessages.filter((message) =>
                message.issueId ? message.issueId === issueId : message.issueIndex === issueIndex
            );
            const deletedMessages = currentMultiplication.deletedIssuesMessages ?? [];

            return [...filteredMessages, ...deletedMessages];
        };

        return [
            ...currentMultiplication.issues.map((issue, index) => ({
                ...issue,
                messages: getMessagesByIssueIdWithDeletedMessages(issue.id, index),
            })),
            ...deletedIssues,
        ];
    };

    const handleClose = (shouldClose, pendingState) => {
        setIsPending(pendingState);
        if (shouldClose) {
            resetMultiplicationsMatrix();
            handleCloseDialog();
        }
    };

    const createMultiplication = (shouldClose = false) => {
        if (compareMultiplications(initialState, getMultiplication())) {
            handleClose(shouldClose, { ...isPending, create: false });
            return;
        }

        if (isPending.create) {
            return;
        }

        shouldClose && setIsClosing(true);

        const params = {
            customCategories,
            id: multiplicationId,
            idMultiplicationComponent: multipliedComponent.id,
            linkedAttribute: activeAttribute.id,
            productId: productId,
            teamId: teamId || '',
            ...getMultiplicationDetails(),
        };

        params.imagesLibrary = params.imagesLibrary.map((image) => {
            const imageCopy = {...image};
            delete imageCopy.imageSource;
            return imageCopy;
        })

        delete params.imagesLibrary.imageSource;

        setIsPending({ ...isPending, create: true });

        const onSuccess = (response) => {
            const issues = getIssuesWithMessages() || [];

            const issuesParams = {
                ideaId: response.id || '',
                issues,
            };

            const onSuccessUpdateIssues = () => {};

            const onErrorUpdateIssues = (error) => {
                console.error(error);
            };

            if (shouldClose) {
                createIssuesWithMessages(issuesParams, onSuccessUpdateIssues, onErrorUpdateIssues);
            }

            setIsNew(false);
            setMultiplicationId(response.id);
            handleClose(shouldClose, { ...isPending, create: false });
        };

        const onError = (error) => {
            console.error(error);
            handleClose(shouldClose, { ...isPending, create: false });
        };

        createIdea(params, onSuccess, onError);
    };

    const resetMultiplicationsMatrix = () => {
        const currentMultiplication = getMultiplication();

        const issuesToDelete = currentMultiplication.issues.map((issue) => ({ id: issue.id })) || [];
        const params = {
            issues: issuesToDelete,
            ideaId: multiplicationId || '',
        };
        const onSuccess = () => {};
        const onError = (error) => {
            console.error(error);
        };

        params.ideaId && createIssuesWithMessages(params, onSuccess, onError);

        currentMultiplication.potentialBenefitsOfMultiplication.forEach((benefit) => {
            benefit.target.forEach(target => {
                if (isDefaultCategory(target)) return;
                
                removeCustomCategory(target);
            })
        });

        setMultiplication({ ...initialState });
        updateUploadInfo({ ...initialUpload });
        setCurrentTab(0);
        setCurrentPage(0);
        setIsNew(!edit);
    };

    const updateValues = () => {
        updateMultiplicationDetails(false);
    };

    const updateMultiplicationDetails = () => {
        const currentMultiplication = multiplicationsMatrix;

        if (Object.keys(currentMultiplication).length) {
            const benefitsOfMultiplication = currentMultiplication.potentialBenefitsOfMultiplication;
            const hasBenefitsOfMultiplication = benefitsOfMultiplication && benefitsOfMultiplication.length > 0;
            const issues = currentMultiplication.issues;
            const hasIssues = issues && issues.length > 0;
            const issuesMessages = [
                ...messages,
                ...(currentMultiplication.issuesMessages ? currentMultiplication.issuesMessages : []),
            ];
            const hasIssuesMessages = issuesMessages && issuesMessages.length > 0;
            const image = currentMultiplication.image;
            const currentRating = currentMultiplication.rating === null ? null : currentMultiplication.rating === 0 ? 0 : currentMultiplication.rating;
            const currentFeasibleRating = currentMultiplication.feasibleRating === null ? null : currentMultiplication.feasibleRating === 0 ? 0 : currentMultiplication.feasibleRating;

            const params = {
                existingMultiplication: currentMultiplication.existingMultiplication,
                isIdeaNew: currentMultiplication.potentialBenefits,
                isIdeaFeasible: currentMultiplication.typeMultiplication,
                score: currentRating,
                feasibleScore: currentFeasibleRating,
                potentialBenefitsOfMultiplication: (hasBenefitsOfMultiplication && benefitsOfMultiplication) || [
                    { name: '', description: '', target: [], userInfo: '' },
                ],
                issues: (hasIssues && issues) || [{ comment: '', description: '', createdByUser: '' }],
                issuesMessages: (hasIssuesMessages && issuesMessages) || [],
                ideaName: currentMultiplication.multiplicationName || '',
                comment: currentMultiplication.comments || '',
                image: image || initialState.image,
                imagesLibrary: currentMultiplication.imagesLibrary || initialState.imagesLibrary,
                removeBenefit: currentMultiplication.removeBenefit,
            };

            if (image && image.name) {
                updateUploadInfo({
                    ...initialUpload,
                    name: image.originalName,
                    date: image.date,
                    size: image.size,
                    uploadName: image.name,
                    uploaded: true,
                    id: image.id,
                });
            }
            const updatedDependency = { ...getMultiplication(), ...params };
            handleSetMultiplication(updatedDependency);
            setSavedMultiplication({ ...updatedDependency });
        } else {
            handleSetMultiplication({ ...initialState });
            setSavedMultiplication({ ...initialState });
        }
    };

    const updateMultiplicationsMatrix = (shouldClose = false) => {
        if (!multiplicationId) {
            return;
        }

        shouldClose && setIsClosing(true);

        const params = {
            id: multiplicationId,
            idMultiplicationComponent: multipliedComponent.id,
            customCategories: getCustomCategories(),
            linkedAttribute: activeAttribute.id,
            productId: productId,
            teamId: teamId || '',
            ...getMultiplicationDetails(),
        };

        delete params.image.imageSource;

        params.imagesLibrary.map((image) => {
            const imageCopy = {...image};
            delete imageCopy.imageSource;
            return imageCopy;
        })
        
        setIsPending({ ...isPending, update: true });
        const onSuccess = () => {
            handleClose(shouldClose, { ...isPending, update: false });
            setIsLoadingImage(false);
        };

        const onError = (error) => {
            console.error(error);
            handleClose(shouldClose, { ...isPending, update: false });
        };

        const issues = getIssuesWithMessages() || [];

        const issuesParams = {
            ideaId: multiplicationId || '',
            issues,
        };

        const onSuccessUpdateIssues = () => {};

        const onErrorUpdateIssues = (error) => {
            console.error(error);
        };

        if (shouldClose && !isPending.update) {
            createIssuesWithMessages(issuesParams, onSuccessUpdateIssues, onErrorUpdateIssues);
        }

        updateIdea(params, onSuccess, onError);
    };

    const selectCurrentMultiplicationImageFromLibrary = (index) => {
        const currentMultiplication = getMultiplication();
        handleSetMultiplication({ ...currentMultiplication, image: currentMultiplication.imagesLibrary[index] });
    }

    const removeImageFromLibrary = (id) => {
        const currentMultiplication = getMultiplication();
        const imagesLibrary = [...currentMultiplication.imagesLibrary];
        const indexOfSelectedImage = imagesLibrary.findIndex((image) => image.id === id);
        
        if (id===currentMultiplication.image.id) {
            const newCurrentImageIndex=indexOfSelectedImage ? imagesLibrary.length % indexOfSelectedImage: imagesLibrary.length-1;
            
            handleSetMultiplication({ 
                ...currentMultiplication,
                image: imagesLibrary.length - 1 ? imagesLibrary[newCurrentImageIndex] : initialState.image,
                imagesLibrary: imagesLibrary.filter((image) => image.id !== id),
            });
            return newCurrentImageIndex;
        }
        handleSetMultiplication({ ...currentMultiplication, imagesLibrary: imagesLibrary.filter((image) => image.id!==id) });
        return imagesLibrary.findIndex((image) => image.id === currentMultiplication.image.id);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        handleSetMultiplication({ ...getMultiplication(), [name]: value });
    };

    const handleAddIssue = () => {
        const currentDependency = getMultiplication();

        const lastIssue = currentDependency?.issues[currentDependency?.issues?.length - 1];

        if (lastIssue?.comment || currentDependency?.issues?.length === 0) {
            handleSetMultiplication({ ...currentDependency, issues: [...currentDependency.issues, { comment: '', description: '', createdByUser: '' }] });
        }
    };

    const handleRemoveIssue = (index) => {
        const currentDependency = getMultiplication();
        const filteredIssues = currentDependency.issues.filter((issue, issueIndex) => issueIndex !== index);
        const filteredMessages = currentDependency.issuesMessages.map((message) => {
            if (message.issueIndex > index) {
                return { ...message, issueIndex: --message.issueIndex };
            } else if (message.issueIndex === index) {
                return {};
            }
            return message;
        });
        handleSetMultiplication({
            ...currentDependency,
            issues: [...filteredIssues],
            issuesMessages: [...filteredMessages],
            deletedIssues: [
                ...(currentDependency.deletedIssues ? currentDependency.deletedIssues : []),
                { id: currentDependency.issues[index].id },
            ],
        });
    };

    const removeLastIssue = (index, idIssues) => {
        const currentMultiplication = getMultiplication();
        const foundIssue = currentMultiplication.issues.find((_, issueIndex) => issueIndex === index);

        let filteredMessages = [];

        if (idIssues) {
            filteredMessages = currentMultiplication.issuesMessages.filter((message) => idIssues !== message.issueId);
        } else {
            filteredMessages = currentMultiplication.issuesMessages.filter((message) => message.issueIndex !== index);
        };
            
        foundIssue.comment = '';
        foundIssue.description = '';
        foundIssue.createdByUser = '';

        handleSetMultiplication({
            ...currentMultiplication,
            issues: [...currentMultiplication.issues],
            issuesMessages: [...filteredMessages],
            deletedIssues: [
                ...(currentMultiplication.deletedIssues ? currentMultiplication.deletedIssues : []),
                { id: currentMultiplication.issues[index].id },
            ],
        });
    };

    const removeMessage = (indexMessage, idSavedMessage) => {
        const currentMultiplication = getMultiplication();
        let filteredMessages = [];

        if (idSavedMessage) {
            filteredMessages = currentMultiplication.issuesMessages.filter((message) => idSavedMessage !== message._id);
        } else {
            filteredMessages = currentMultiplication.issuesMessages.filter((message, index) => index !== indexMessage);
        };

        handleSetMultiplication({
            ...currentMultiplication,
            issuesMessages: [...filteredMessages],
        });
    };
    
    const removeLastMessage = (indexMessage, issueId, idSavedMessage) => {
        const currentMultiplication = getMultiplication();
        let foundMessage = {};

        if (idSavedMessage) {
            foundMessage = currentMultiplication.issuesMessages.find((message) => idSavedMessage === message._id);
        } else {
            foundMessage = currentMultiplication.issuesMessages.find((message, index) => indexMessage === index && issueId === message.issueIndex);
        };

        foundMessage.message = '';

        handleSetMultiplication({
            ...currentMultiplication, 
            issuesMessages: [
                ...currentMultiplication.issuesMessages
            ]
        });
    };

    const handleIssueChange = (index, value, name) => {
        const currentDependency = { ...getMultiplication() };
        const changedIssues = [...currentDependency.issues];
        if (name === "comment") {
            changedIssues[index].comment = value;
            changedIssues[index].createdByUser = `${props.userFullName.name} ${props.userFullName.lastName}`;
        };

        if (name === "description") {
            changedIssues[index].description = value;
        };

        handleSetMultiplication({ ...currentDependency, issues: [...changedIssues] });
    };

    const handleAddIssueMessage = (issueId, issueIndex) => {
        const currentDependency = getMultiplication();
        const filteredMessages =
            currentDependency?.issuesMessages.filter((message) =>
                message.issueId ? message.issueId === issueId : message.issueIndex === issueIndex
            ) || [];

        const lastMessage = filteredMessages[filteredMessages.length - 1];
        const canBeCreated = !lastMessage || lastMessage.message !== '';

        if (canBeCreated) {
            handleSetMultiplication({
                ...currentDependency,
                issuesMessages: [...currentDependency.issuesMessages, { message: '', issueId, issueIndex }],
            });
        }
    };

    const handleIssueMessageChange = (value, index, userId, issueIndex) => {
        const currentMultiplication = getMultiplication();
        const changedIssuesMessages = [...currentMultiplication.issuesMessages];
        changedIssuesMessages[index] = { message: value, userId, issueIndex }; 
        handleSetMultiplication({ ...currentMultiplication, issuesMessages: changedIssuesMessages });
    };

    const updateBenefit = (name, index, multiplication, value) => {
        const changedBenefits = [...multiplication.potentialBenefitsOfMultiplication];
        const newBenefit = { ...changedBenefits[index] };
        newBenefit[name] = value;
        changedBenefits[index] = newBenefit;
        handleSetMultiplication({ ...multiplication, potentialBenefitsOfMultiplication: changedBenefits });
    };

    const handleTargetChange = (index, { id, name }) => {
        const currentMultiplication = getMultiplication();
        
        const updatedTarget = [...currentMultiplication.potentialBenefitsOfMultiplication[index].target];
        const indexUpdatedTarget = updatedTarget.findIndex(target => target.name === name);

        if (indexUpdatedTarget === -1) {
            updatedTarget.push({ id, name });
        } else {
            updatedTarget.splice(indexUpdatedTarget, 1);
        }

        updateBenefit("target", index, currentMultiplication, updatedTarget);
    };

    const handleBenefitChange = (index, value, name) => {
        const currentMultiplication = getMultiplication();
        updateBenefit(name, index, currentMultiplication, value);
    }
    
    const handleDeleteOption = (changedLabel) => {
        const currentMultiplication = getMultiplication();
        
        currentMultiplication.potentialBenefitsOfMultiplication.forEach(benefit => {
            const updatedTargets = benefit.target.filter((targetElement) => targetElement.name !== changedLabel);
            benefit.target = updatedTargets
        });

        handleSetMultiplication({
            ...currentMultiplication,
            potentialBenefitsOfMultiplication: currentMultiplication.potentialBenefitsOfMultiplication
        })

        removeCustomCategory(changedLabel);
    }

    const addBenefit = () => {
        const currentMultiplication = getMultiplication();
        handleSetMultiplication({
            ...currentMultiplication,
            potentialBenefitsOfMultiplication: [
                ...currentMultiplication.potentialBenefitsOfMultiplication,
                { name: '', description: '', target: [], userInfo: '' },
            ],
        });
    };

    const removeBenefit = (index) => {
        const currentMultiplication = getMultiplication();
        removeCustomCategory(currentMultiplication.potentialBenefitsOfMultiplication[index].target);
        const filteredBenefits = currentMultiplication.potentialBenefitsOfMultiplication.filter(
            (benefit, benefitIndex) => benefitIndex !== index
        );
        handleSetMultiplication({ ...currentMultiplication, potentialBenefitsOfMultiplication: [...filteredBenefits] });
    };

    const removeLastBenefit = (index) => {
        const currentMultiplication = getMultiplication();
        removeCustomCategory(currentMultiplication.potentialBenefitsOfMultiplication[index].target);
        const foundBenefit = currentMultiplication.potentialBenefitsOfMultiplication.find((benefit, benefitIndex) => benefitIndex === index);

        foundBenefit.name = '';
        foundBenefit.description = '';
        foundBenefit.target = [];
        foundBenefit.userInfo = '';

        handleSetMultiplication({
            ...currentMultiplication, 
            potentialBenefitsOfMultiplication: [
                ...currentMultiplication.potentialBenefitsOfMultiplication
            ]
        });
    };

    const multiplicationComponents = {
        firstComponent: projectName || '',
        secondComponent: multipliedComponent.name || '',
        activeAttribute: activeAttribute.name || '',
    };

    const handleSetCurrentTab = (value) => {
        if (value === 0 || value === 1) {
            setCurrentPage(value);
        } else {
            setCurrentPage(value + 1);
        }

        setCurrentTab(value);
        handleSave();
    };

    const handleSave = () => {
        const upToDate = compareMultiplications(savedMultiplication, multiplication);

        if (!upToDate) {
            const updatedMultiplication = { ...getMultiplication() };

            setSavedMultiplication(updatedMultiplication);
            isNew ? createMultiplication() : updateMultiplicationsMatrix();
        }
    };

    const compareMultiplications = (firstItem, secondItem) => {
        const keys = Object.keys(firstItem);
        let isEqual = true;

        for (let key of keys) {
            if (key === 'potentialBenefitsOfMultiplication') {
                const firstBenefit =
                    firstItem[key] && firstItem[key].filter((benefit) => benefit.name && benefit.target && benefit.description && benefit.userInfo);
                const secondBenefit =
                    secondItem[key] && secondItem[key].filter((benefit) => benefit.name && benefit.target && benefit.description && benefit.userInfo);

                if (firstBenefit.length !== secondBenefit.length) {
                    isEqual = false;
                    break;
                }

                for (let benefit of firstBenefit) {
                    const foundBenefit = secondBenefit.find(
                        (item) => item.name === benefit.name && item.target === benefit.target && item.description === benefit.description && item.userInfo === benefit.userInfo
                    );
                    if (!foundBenefit) {
                        isEqual = false;
                        break;
                    }
                }

                continue;
            }

            if (key === 'issues') {
                if (firstItem[key].join('') !== secondItem[key].join('')) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (key === 'issuesMessages') {
                if (firstItem[key].join('') !== secondItem[key].join('')) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (key === 'image') {
                if (firstItem[key].name !== secondItem[key].name) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (firstItem[key] !== secondItem[key]) {
                isEqual = false;
                break;
            }
        }

        return isEqual;
    };

    const prevEnabled = () => {
        if (currentTab === 0) {
            return false;
        }

        return true;
    };

    const nextEnabled = () => {
        if (currentTab === 2) {
            return false;
        }

        return true;
    };

    const isCompleted = () => {
        const currentMultiplication = getMultiplication();
        return (
            currentMultiplication.isIdeaNew === false ||
            currentMultiplication.isIdeaFeasible === false ||
            (currentMultiplication.isIdeaFeasible === true && currentMultiplication.score > 0)
        );
    };

    const handleSetMultiplication = (value) => {
        setMultiplication(value);
    };

    const getMultiplication = () => {
        return {...multiplication, image:{...multiplication.image}};
    };

    const handleImageUpload = (file, imageSource = null) => {
        const CancelToken = axios.CancelToken;

        const formData = new FormData();
        formData.append('image', file, file.name);
        updateUploadInfo({ progress: 0, cancel: null, uploaded: false, name: file.name, size: file.size });

        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                updateUploadInfo({ progress: completed });
            },
            cancelToken: new CancelToken(function executor(c) {
                updateUploadInfo({ cancel: c });
            }),
        };

        const onSuccess = (response) => {
            updateUploadInfo({ uploaded: true, date: new Date(), uploadName: response.filename, id: response.imageId });
            const image = {
                name: response.filename,
                originalName: file.name,
                id: response.imageId,
                imageSource
            };

            const currentMultiplication = getMultiplication();
                        
            if (!currentMultiplication.imagesLibrary.length) {
                handleSetMultiplication({ ...currentMultiplication, imagesLibrary:[...currentMultiplication.imagesLibrary, image], image });
                return;
            }
            handleSetMultiplication({ ...currentMultiplication, imagesLibrary:[...currentMultiplication.imagesLibrary, image] });
        };

        const onError = (error) => {
            console.error(error);
            if (uploadInfo.name) {
                updateUploadInfo({ ...initialUpload });
            }
        };

        setIsLoadingImage(true);
        uploadImage(formData, config, onSuccess, onError);
    };

    const handleCancelClick = () => {
        setNewGeneratedImage({
            isNew: false,
            urlImage: ""
        });
        uploadInfo.cancel && uploadInfo.cancel('Canceled by user');
        updateUploadInfo({ ...initialUpload });

        const currentMultiplication = getMultiplication();
        handleSetMultiplication({ ...currentMultiplication, image: initialState.image });
    };

    const updateUploadInfo = (value) => {
        if (value) {
            setUploadInfo((state) => ({ ...state, ...value }));
        } else {
            setUploadInfo((state) => ({ ...state, ...initialUpload }));
        }
    };

    const currentUploadInfo = uploadInfo;

    const handleCloseDialog = () => {
        setIsClosing(true);
        const upload = uploadInfo;
        if (upload.name && !upload.uploaded) {
            upload.cancel && upload.cancel('Canceled by user');
        }

        setUploadInfo({ ...initialUpload });
        closeDialog && closeDialog();
    };

    const matrixBodyClass = [1, 2].includes(currentTab) ? ' benefits-container' : '';

    const addCustomCategory = (name) => {
        const foundCategory = customCategories.find((category) => category.name === name);
        if (foundCategory) {
            foundCategory.count++;
            return;
        }
        
        const currentId = customCategories[customCategories.length - 1]?.id;

        setCustomCategories([...customCategories, {
            id: _.isNumber(currentId) ? currentId + 1 : 3,
            name, 
            count: 1
        }]);
    };

    const removeCustomCategory = (name) => {
        const updatedCategories = customCategories.filter((category) => category.name !== name);
        setCustomCategories(updatedCategories);
    };

    const renameCustomCategory = (oldName, newName) => {
        const foundCategoryIndex = customCategories.findIndex((category) => category.name === newName);
        if (foundCategoryIndex !== -1) {
            customCategories[foundCategoryIndex].count++;

            return;
        }

        const multiplication = getMultiplication();
        
        multiplication.potentialBenefitsOfMultiplication.forEach(benefit => {
            const currentTargetIndex = benefit.target.findIndex(target => target.name === oldName);
            
            if (currentTargetIndex !== -1) {
                benefit.target.splice(currentTargetIndex, 1, { id: benefit.target[currentTargetIndex].id, name: newName });
            }
        }) 

        handleSetMultiplication({
            ...multiplication,
            potentialBenefitsOfMultiplication: multiplication.potentialBenefitsOfMultiplication
        })
            
        const copiedArray = [...customCategories];
        const findOldCategoryIndex = customCategories.findIndex((category) => category.name === oldName);

        const { count, id } = customCategories[findOldCategoryIndex];

        copiedArray.splice(findOldCategoryIndex, 1, { id, count, name: newName });
        setCustomCategories(copiedArray);
    }

    const getCustomCategories = () => {
        return customCategories.filter((category) => category.count > 0);
    };

    const addIdeasName = (ideasName) => {
        setMultiplication({...multiplication, ideaName: ideasName})
        setOpenGenerateModal(false);
        setSuggestedOptions([]);
    };

    const generateNames = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
             if (code === "30840" || code === "30841") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== "30841");
                return;
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentMultiplication = getMultiplication();
        const description = currentMultiplication.comment ? currentMultiplication.comment : descriptionOfIdea;

        getGeneratedNamesForIdea(
            {
                projectName: productName ? productName : projectName,
                productType,
                projectId: productId,
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const closeErrorWindow = () => {
        setError({
            openErrorModal: false,
            message: '',
            headerError: ''
        });
    };

    const handleChangeScore = (e) => {
        setMultiplication({...multiplication, score: e });
    };

    const handleChangeFeasibleScore = (e) => {
        setMultiplication({...multiplication, isIdeaFeasible: e > 0, feasibleScore: e });
    };

    const selectGenerateOption = (nameOption) => {
        setSelectedGenerateOptions((prev) => {
            const prevSelectedOption = new Set(prev);
            const key = `${nameOption}`;
            prev.has(key) ? prevSelectedOption.delete(key) : prevSelectedOption.add(key);
            return prevSelectedOption;
        });
    };

    const addGenerateBenefits = () => {
        const benefits = [];

        suggestedOptions.map(benefit => {
            if (selectedGenerateOptions.has(`${benefit}`)) {
                const params = {
                    name: benefit.includes("：") ? benefit.split('：')[0] : benefit.split(':')[0],
                    description: benefit.includes("：") ? benefit.split('：')[1] : benefit.split(':')[1],
                    target: [],
                    userInfo: "AI"
                }
                benefits.push(params);
            }
        })

        setMultiplication({
            ...multiplication,
            potentialBenefitsOfMultiplication:[
                ...benefits,
                ...multiplication.potentialBenefitsOfMultiplication,
            ],
        });

        setOpenGenerateModal(false);
        setSelectedGenerateOptions(new Set());
        setSuggestedOptions([]);
    };

    const generateBenefits = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
             if (code === "30840" || code === "30841") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== "30841");
                return;
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentSubtraction = getMultiplication();
        const description = currentSubtraction.comment ? currentSubtraction.comment : descriptionOfIdea;

        getGeneratedBenefits(
            {
                projectName: productName ? productName : projectName,
                projectId: productId,
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const closeGenerateModal = () => {
        setOpenGenerateModal(false);
        setSuggestedOptions([]);
    };

    const handleRightClick = () => {
        if (currentTab === 1 
            && (multiplication.score === null || multiplication.score === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_IMPACT"),
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        handleSetCurrentTab(currentTab + 1);
        setCurrentPage(currentPage + 1);
    };

    const handleLeftClick = () => {
        handleSetCurrentTab(currentTab - 1);
        setCurrentPage(currentPage - 1);
    };

    const getMessageForCheckRating = () => {
        return (
            <p className="flex-column mt-25px">
                <span style={{ margin: "0" }}>{getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_IMPACT")}</span>
                <span>{getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_FEASIBILITY")}</span>
            </p>
        );
    };

    const checkRating = () => {
        if ((currentTab === 2) 
            && (multiplication.score === null || multiplication.score === undefined)
            && (multiplication.feasibleScore === null || multiplication.feasibleScore === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getMessageForCheckRating(),
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        if ((currentTab === 2) 
            && (multiplication.score)
            && (multiplication.feasibleScore === null || multiplication.feasibleScore === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_FEASIBILITY"),
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        if ((currentTab === 2) 
            && (multiplication.feasibleScore)
            && (multiplication.score === null || multiplication.score === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_IMPACT"),
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };        

        isNew ? createMultiplication(true) : updateMultiplicationsMatrix(true);
    };

    const changeCurrentTab = (index) => {
        setCurrentTab(index);
    };

    const generateChallenges = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
             if (code === "30840" || code === "30841") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== "30841");
                return;
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentDependency = getMultiplication();
        const description = currentDependency.comment ? currentDependency.comment : descriptionOfIdea;

        getGeneratedChallenges(
            {
                projectName: productName ? productName : projectName,
                projectId: productId,
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const addGenerateChallenges = () => {
        const generatedIssues = [];

        suggestedOptions.map(issue => {
            if (selectedGenerateOptions.has(`${issue}`)) {
                const params = {
                    comment: issue.includes("：") ? issue.split('：')[0] : issue.split(':')[0],
                    description: issue.includes("：") ? issue.split('：')[1] : issue.split(':')[1],
                    createdByUser: "AI"
                }
                generatedIssues.push(params);
            }
        })

        setMultiplication({
            ...multiplication,
            issues:[
                ...generatedIssues,
                ...multiplication.issues,
            ],
        });

        setOpenGenerateModal(false);
        setSelectedGenerateOptions(new Set());
        setSuggestedOptions([]);
    };

    const convertImage = async (imageBase64) => {
        try {
            const base64Response = await fetch(`data:image/png;base64,${imageBase64}`);
            const blobImage = await base64Response.blob();

            let dt = new DataTransfer();
            dt.items.add(new File([blobImage], 'image.png', { type: blobImage.type }));
            const fileImage = dt.files[0];
            const bufferImage = await fileImage.arrayBuffer();
    
            const reader = new FileReader();
    
            reader.onload = () => {
                const imageUrl = `data:image/png;base64,${Buffer.from(bufferImage).toString('base64')}`;
                setPotentialGeneratedImageUrl(imageUrl);
            };
    
            reader.readAsArrayBuffer(fileImage);
    
            return fileImage;
        } catch (error) {
            setError({
                openErrorModal: true,
                message: error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
        };
    };

    const handleAcceptPotentialImage = () => {
        setGeneratedImage(potentialGeneratedImageUrl);
        handleImageUpload(potentialGeneratedImageFile, potentialGeneratedImageUrl);
        setIsOpenGenerateImageModal(false);
    }

    const generateImage = () => {
        setIsOpenGenerateImageModal(true);

        const onSuccess = async (imageBase64) => {
            setNewGeneratedImage({
                isNew: true,
                urlImage: `data:image/png;base64,${imageBase64}`
            });
            const fileImage = await convertImage(imageBase64);

            setPotentialGeneratedImageFile(fileImage);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
             if (code === "30840" || code === "30841") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== "30841");
                return;
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentMultiplication = getMultiplication();
        const description = currentMultiplication.comment ? currentMultiplication.comment : descriptionOfIdea;

        getGeneratedImage(
            {
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const header = {
        projectName: project.product.name,
        productName,
        component: multiplicationComponents.secondComponent,
        multiplicationCount: multiplicationComponents.multiplicationCount,
        activeAttribute: multiplicationComponents.activeAttribute,
    };

    const getMultiplicationHeader = () => {
        return (
            <p className='multiplication-dialog-header-image'>
                {getTranslation('SUBTRACTION_DESCRIPTION_IMAGINE_A')}
                <u>{header.productName ? header.productName : header.projectName}</u> 
                {getTranslation('DESCRIBE_NEW_DEPENDENCY_WHERE_THE')}
                <u>{header.component}</u>

                {productType === 'process' 
                    ? getTranslation('MULTIPLICATION_DESCRIPTION_STEP_IS_MULTIPLIED') 
                    : getTranslation('MULTIPLICATION_DESCRIPTION_IS_MULTIPLIED')
                }

                {header.activeAttribute && (
                    <>
                        {` ${getTranslation('MULTIPLICATION_DESCRIPTION_AND_ITS_ATTRIBUTES')}`}
                        <u>{header.activeAttribute}</u>
                        {getTranslation('MULTIPLICATION_DESCRIPTION_HAS_NEW_VALUE')}
                    </>
                )}
            </p>
        );
    };

    const descriptionOfIdea = `
        ${productName ? productName : projectName}
        ${getTranslation('DESCRIBE_NEW_DEPENDENCY_WHERE_THE')}
        ${multiplicationComponents.secondComponent}
        ${productType === 'process' 
            ? getTranslation('MULTIPLICATION_DESCRIPTION_STEP_IS_MULTIPLIED') 
            : getTranslation('MULTIPLICATION_DESCRIPTION_IS_MULTIPLIED')
        }
        ${multiplicationComponents.activeAttribute 
            ? `${getTranslation('MULTIPLICATION_DESCRIPTION_AND_ITS_ATTRIBUTES')} ${multiplicationComponents.activeAttribute} ${getTranslation('MULTIPLICATION_DESCRIPTION_HAS_NEW_VALUE')}`
            : ""
        }
    `.replace(/[\s]+/gi, ' ');

    return (
        <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false}>
            <div className='subtraction-dialog-wrapper'>
                <MultiplicationModalHeader
                    closeDialog={handleCloseDialog}
                    multiplication={getMultiplication()}
                    currentTab={currentTab}
                    setCurrentTab={handleSetCurrentTab}
                    isCompletedIdea={isCompletedIdea}
                    productType={productType}
                    getMultiplicationHeader={getMultiplicationHeader}
                    changeCurrentTab={changeCurrentTab}
                />

                <div className={'subtraction-dialog-body flex-column' + matrixBodyClass}>
                    <MultiplicationModalTabs
                        selectCurrentMultiplicationImageFromLibrary={selectCurrentMultiplicationImageFromLibrary}
                        removeImageFromLibrary={removeImageFromLibrary}
                        handleAcceptPotentialImage={handleAcceptPotentialImage}
                        potentialGeneratedImageUrl={potentialGeneratedImageUrl}                    
                        currentTab={currentTab}
                        multiplication={getMultiplication()}
                        setMultiplication={handleSetMultiplication}
                        handleChange={handleChange}
                        userFullName={props.userFullName}
                        userId={props.userId}
                        addBenefit={addBenefit}
                        removeBenefit={removeBenefit}
                        removeLastBenefit={removeLastBenefit}
                        handleBenefitChange={handleBenefitChange}
                        handleTargetChange={handleTargetChange}
                        addIssue={handleAddIssue}
                        removeIssue={handleRemoveIssue}
                        removeLastIssue={removeLastIssue}
                        removeMessage={removeMessage}
                        removeLastMessage={removeLastMessage}
                        handleIssueChange={handleIssueChange}
                        uploadInfo={currentUploadInfo}
                        newGeneratedImage={newGeneratedImage}
                        handleCancelClick={handleCancelClick}
                        handleFileSelect={handleImageUpload}
                        customCategories={getCustomCategories()}
                        isLoadingImage={isLoadingImage}
                        currentPage={currentPage}
                        addIssueMessage={handleAddIssueMessage}
                        handleIssueMessageChange={handleIssueMessageChange}
                        productType={productType}
                        header={header}
                        handleGenerateNames={generateNames}
                        suggestedLoading={suggestedLoading}
                        addIdeasName={addIdeasName}
                        handleChangeScore={handleChangeScore}
                        handleChangeFeasibleScore={handleChangeFeasibleScore}
                        suggestedOptions={suggestedOptions}
                        openGenerateModal={openGenerateModal}
                        closeGenerateModal={closeGenerateModal}
                        selectGenerateOption={selectGenerateOption}
                        selectedGenerateOptions={selectedGenerateOptions}
                        addGenerateBenefits={addGenerateBenefits}
                        handleGenerateBenefits={generateBenefits}
                        handleGenerateChallenges={generateChallenges}
                        addGenerateChallenges={addGenerateChallenges}
                        handleGenerateImage={generateImage}
                        isOpenGenerateImageModal={isOpenGenerateImageModal}
                        closeDialogGenerateImage={() => setIsOpenGenerateImageModal(false)}
                        generatedImage={generatedImage}
                        handleDeleteOption={handleDeleteOption}
                        addCustomCategory={addCustomCategory}
                        handleRenameOption={renameCustomCategory}
                    />
                </div>

                <MultiplicationModalFooter
                    resetMultiplicationsMatrix={resetMultiplicationsMatrix}
                    prevEnabled={prevEnabled}
                    nextEnabled={nextEnabled}
                    isCompleted={isCompleted}
                    handleRightClick={handleRightClick}
                    handleLeftClick={handleLeftClick}
                    disabledNextButton={disabledNextButton}
                    checkRating={checkRating}
                    currentTab={currentTab}
                />

                {error.openErrorModal &&
                    <ErrorMessageModal 
                        message={error.message}
                        closeModalWindow={closeErrorWindow}
                        textButton={getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
                        handleButton={closeErrorWindow}
                        header={getTranslation("RATING_REQUIRED_MODAL_HEADER")}
                    />
                }

                {paymentInfo.key && (
                    <CheckoutModal
                        isOpenAISubscription
                        closeDialog={() => setPaymentInfo((prev) => ({ ...prev, key: '' }))}
                        subscriptionInfo={paymentInfo}
                        history={history}
                        mode='payment'
                        setGeneratedOpenAIRequests={setGeneratedOpenAIRequests}
                    />
                )}

                {isOpenBuyCreditsgModal && (
                    <BuyMoreAICreditsModal
                        setPaymentInfo={setPaymentInfo}
                        closeDialog={() => setIsOpenBuyCreditsgModal(false)}
                    />
                )}

                {isOpenWarningModal && (
                    <WarningNotTokensModal
                        closeDialog={() => {
                            setIsOpenWarningModal(false);
                        }}
                        openBuyModal={() => setIsOpenBuyCreditsgModal(true)}
                        history={history}
                        isEnoghTokensForImage={isEnoghTokensForImage}
                    />
                )}
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    language: state.auth.userInfo.language,
});

const mapDispatchToProps = {
    setGeneratedOpenAIRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiplicationEditModal);
