import React from 'react';

import { getTranslation } from '../../../helpers/getLanguage';

import './styles.css';

const MessageShowNotFound = ({ history }) => {
    const navigateToAllProjects = () => {
        history.push(`/`);
    };

    return (
        <div className='flex-column message-not-found-component'>
            <span>{getTranslation('PROJECT_NOT_EXIST_MESSAGE')}</span>
            {!window.location.href.includes('view') && (
                <span>
                    <span className='pointer underlined' onClick={navigateToAllProjects}>
                        {getTranslation('PROJECT_NOT_EXIST_CREATE_OR_SELECT')}
                    </span>
                </span>
            )}
        </div>
    );
};

export default MessageShowNotFound;
