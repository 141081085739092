import React from 'react';

import { ReactComponent as CellIcon } from '../../assets/images/cells-icon.svg';

import { getTranslation } from '../../helpers/getLanguage';

const colors = {
    1: '#ffcd67',
    2: '#f9ae9f',
    3: {
        1: '#daf1e1',
        2: '#b5e3c3',
        3: '#91d5a5',
        4: '#6cc687',
        5: '#5ac57a',
    },
};

const colorClass = {
    1: 'yellow',
    2: 'red',
    3: 'green',
};

const CellDropdownElem = ({ row, column, details, handleOpenDetails }) => {
    const getRating = () => {
        const rating = details.rating || 0;
        const feasibleRating = details.feasibleRating || 0;

        if (!details?.feasibleRating || details.feasibleRating < 0) {
            return Math.min(rating, 5);
        }

        const sum = rating + feasibleRating;
        return Math.round(sum / 2);
    };

    const getStatusColor = () => {
        if (details.status < 3) {
            return colors[details.status];
        }

        return colors[details.status][details.rating];
    };

    const isPublicView = window.location.href.includes('view-matrix');

    return (
        <div className={'cell-dropdown-row flex align-center ' + colorClass[details.status]}>
            <div className='cell-dropdown-row-color-rating' style={{ backgroundColor: getStatusColor() }}></div>
            <div className='cell-dropdown-row-rating'>
                {details.status === 3 && details.rating > 0 && (
                    <div className='cell-dropdown-idea-score flex flex-center'>
                        <span>{getRating()}</span>
                    </div>
                )}
            </div>
            <div className='cell-dropdown-row-name flex'>
                <span className='cell-dropdown-row-text'>
                    {details.name === '' ? `Potential idea #${details.index + 1}` : details.name}
                </span>
            </div>
            <div className='cell-dropdown-row-buttons-container flex align-center'>
                {!isPublicView && (
                    <button
                        className='open-details-button edit flex'
                        onClick={() => handleOpenDetails(row, column, details)}
                    >
                        <CellIcon />
                        <span>{getTranslation('EDIT_IDEA_BUTTON')}</span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default CellDropdownElem;
