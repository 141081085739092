import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Dialog from '../Dialog';

import { createIdea, updateIdea, getMessagesByIssuesIds, createIssuesWithMessages } from '../../../api/replacement';
import { getGeneratedBenefits, getGeneratedNamesForIdea, getGeneratedChallenges, getGeneratedImage } from '../../../api/ai';
import { uploadImage } from '../../../api/uploads';
import _ from 'lodash';

import ReplacementModalHeader from './ReplacementModalHeader';
import ReplacementModalTabs from './ReplacementModalTabs';
import ReplacementModalFooter from './ReplacementModalFooter';
import WarningNotTokensModal from '../../ComponentsDashboard/WarningNotTokensModal';
import BuyMoreAICreditsModal from '../BuyMoreAICreditsModal';
import CheckoutModal from '../CheckoutModal';
import { setGeneratedOpenAIRequests } from '../../../actions/projectActions';
import ErrorMessageModal from '../../../components/dialogs/ErrorMessageModal';
import { getTranslation } from '../../../helpers/getLanguage';
import './styles.css';

const IDEAS_IDENTIFICATION = {
    EMPTY_IDEA: 0,
    IN_PROGRESS_IDEA: 1,
    NOT_FEASIBLE_IDEA: 2,
    COMPLETED_IDEA: 3,
};

const initialState = {
    existingReplacement: null,
    isIdeaNew: null,
    isIdeaFeasible: null,
    score: null,
    feasibleScore: null,
    potentialBenefitsOfReplacement: [{ name: '', description: '', target: [], userInfo: '' }],
    issues: [{ comment: '', description: '', createdByUser: '' }],
    issuesMessages: [],
    ideaName: '',
    removeBenefit: '',
    comment: '',
    image: { name: '', originalName: '', id: '' },
    imagesLibrary: [],
};

const initialPaymentInfo = {
    intervalCount: 1,
    quantity: 1,
    key: '',
    interval: null,
    openAIRequests: null,
    total: null,
    promoCode: '',
    promoCodeOpenAI: '',
};

const initialUpload = {
    progress: 100,
    cancel: null,
    name: '',
    date: '',
    size: 0,
    uploaded: false,
    uploadName: '',
};

const isDefaultCategory = (value) => ['Consumer', 'Company', 'Other'].includes(value);

const ReplacementEditModal = ({
    closeDialog,
    edit,
    remoteComponent,
    replacingComponent,
    productId,
    project,
    replacementMatrix,
    teamId,
    categories = [],
    productType,
    productName,
    language,
    history,
    setGeneratedOpenAIRequests,
    ...props
}) => {
    const [replacement, setReplacement] = useState(_.cloneDeep(initialState));
    const [currentTab, setCurrentTab] = useState(0);
    const [isNew, setIsNew] = useState(!edit);
    const [replacementId, setReplacementId] = useState(replacementMatrix.id);
    const [savedReplacement, setSavedReplacement] = useState(_.cloneDeep(initialState));
    const [isPending, setIsPending] = useState({ create: false, update: false });
    const [uploadInfo, setUploadInfo] = useState({ ...initialUpload });
    const [isClosing, setIsClosing] = useState(false);
    const [customCategories, setCustomCategories] = useState([...categories]);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [isCompletedIdea, setIsCompletedIdea] = useState(false);
    const [messages, setMessages] = useState([]);
    const [suggestedLoading, setSuggestedLoading] = useState(false);
    const [error, setError] = useState({
        openErrorModal: false,
        message: '',
        headerError: ''
    });
    const [suggestedOptions, setSuggestedOptions] = useState([]);
    const [selectedGenerateOptions, setSelectedGenerateOptions] = useState(new Set());
    const [openGenerateModal, setOpenGenerateModal] = useState(false);
    const [isOpenGenerateImageModal, setIsOpenGenerateImageModal] = useState(false);
    const [generatedImage, setGeneratedImage] = useState('');
    const [potentialGeneratedImageFile, setPotentialGeneratedImageFile] = useState(null)
    const [potentialGeneratedImageUrl, setPotentialGeneratedImageUrl] = useState("")
    const [newGeneratedImage, setNewGeneratedImage] = useState({
        isNew: false,
        urlImage: ''
    });
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
    const [isOpenBuyCreditsgModal, setIsOpenBuyCreditsgModal] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(initialPaymentInfo);
    const [isEnoghTokensForImage, setIsEnoghTokensForImage] = useState(true);

    const { name: projectName } = project.product;

    const disabledNextButton = (currentTab === 1) && (replacement.score === null || replacement.score === undefined);

    useEffect(() => {
        (async () => {
            let componentExists = true;
            const issuesParams = replacementMatrix.issues ? replacementMatrix.issues.map((issue) => issue.id) : [];
            const params = { issues: issuesParams };

            const onSuccess = (response) => {
                if (componentExists) setMessages((prev) => [...prev, ...response]);
            };

            const onError = (error) => {
                console.error(error);
                handleClose(true, { ...isPending, update: false });
            };

            await getMessagesByIssuesIds(params, onSuccess, onError);

            return () => (componentExists = false);
        })();
    }, []);

    useEffect(() => {
        if (edit) updateValues();
    }, [replacementMatrix, edit, messages]);

    useEffect(() => {
        if (!isClosing) {
            const isUpToDate = savedReplacement.image.name === replacement.image.name;
            if (!isUpToDate) {
                handleSave();
            }
        }
    }, [replacement.image, isClosing, savedReplacement]);

    useEffect(() => {
        if (replacement && replacement.score && replacement.feasibleScore) {
            setIsCompletedIdea(true);
        } else {
            setIsCompletedIdea(false);
        }
    }, [replacement.score, replacement.feasibleScore]);

    const getStatus = () => {
        const currentReplacement = getReplacement();
        const { 
            existingReplacement, 
            isIdeaNew, 
            isIdeaFeasible, 
            score,
            createsBenefits,
            feasibleScore,
            issues,
            potentialBenefitsOfReplacement,
            comment,
            ideaName,
            image
        } = currentReplacement;

        if (isIdeaNew === false || existingReplacement === true || isIdeaFeasible === false || score === 0) {
            return IDEAS_IDENTIFICATION.NOT_FEASIBLE_IDEA;
        }

        if (isIdeaFeasible && score > 0) {
            return IDEAS_IDENTIFICATION.COMPLETED_IDEA;
        }

        if (!createsBenefits 
            && !isIdeaFeasible 
            && score === null 
            && feasibleScore === null 
            && (issues.length === 1 || issues.length === 0) 
            && !issues[0]?.comment.trim()
            && potentialBenefitsOfReplacement.length === 1
            && !potentialBenefitsOfReplacement[0].name.trim()
            && !comment.trim() 
            && !ideaName.trim()
            && !image.id
        ) {
            return IDEAS_IDENTIFICATION.EMPTY_IDEA;
        }

        return IDEAS_IDENTIFICATION.IN_PROGRESS_IDEA;
    };

    const getReplacementDetails = () => {
        const currentReplacement = getReplacement();

        return {
            replacementName: currentReplacement.ideaName,
            potentialBenefitsOfReplacement: currentReplacement.potentialBenefitsOfReplacement.filter((el) => el.name),
            typeReplacement: currentReplacement.isIdeaFeasible,
            issues: currentReplacement.issues.filter((issue) => issue),
            comments: currentReplacement.comment,
            removeBenefit: currentReplacement.removeBenefit,
            rating: currentReplacement.score,
            feasibleRating: currentReplacement.feasibleScore,
            image: currentReplacement.image,
            imagesLibrary: currentReplacement.imagesLibrary || initialState.imagesLibrary,
            status: getStatus(),
            existingReplacement: currentReplacement.existingReplacement,
        };
    };

    const getIssuesWithMessages = () => {
        const currentReplacement = getReplacement();
        const deletedIssues = currentReplacement.deletedIssues ?? [];

        const getMessagesByIssueIdWithDeletedMessages = (issueId, issueIndex) => {
            const filteredMessages = currentReplacement.issuesMessages.filter((message) =>
                message.issueId ? message.issueId === issueId : message.issueIndex === issueIndex
            );
            const deletedMessages = currentReplacement.deletedIssuesMessages ?? [];

            return [...filteredMessages, ...deletedMessages];
        };

        return [
            ...currentReplacement.issues.map((issue, index) => ({
                ...issue,
                messages: getMessagesByIssueIdWithDeletedMessages(issue.id, index),
            })),
            ...deletedIssues,
        ];
    };

    const handleClose = (shouldClose, pendingState) => {
        setIsPending(pendingState);
        if (shouldClose) {
            resetReplacementMatrix();
            handleCloseDialog(shouldClose);
        }
    };

    const createReplacement = (shouldClose = false) => {
        if (compareReplacements(initialState, getReplacement())) {
            handleClose(shouldClose, { ...isPending, create: false });
            return;
        }

        if (isPending.create) {
            return;
        }

        shouldClose && setIsClosing(true);

        const params = {
            customCategories,
            id: replacementId,
            idRemoteComponent: remoteComponent.id,
            idReplacingComponent: replacingComponent.id,
            productId: productId,
            teamId: teamId || '',
            ...getReplacementDetails(false),
        };

        params.imagesLibrary = params.imagesLibrary.map((image) => {
            const imageCopy = {...image};
            delete imageCopy.imageSource;
            return imageCopy;
        })

        delete params.image.imageSource;

        setIsPending({ ...isPending, create: true });

        const onSuccess = (response) => {
            const issues = getIssuesWithMessages() || [];

            const issuesParams = {
                ideaId: response.id || '',
                issues,
            };

            const onSuccessUpdateIssues = () => {};

            const onErrorUpdateIssues = (error) => {
                console.error(error);
            };

            if (shouldClose) {
                createIssuesWithMessages(issuesParams, onSuccessUpdateIssues, onErrorUpdateIssues);
            }

            setIsNew(false);
            setReplacementId(response.id);
            handleClose(shouldClose, { ...isPending, create: false });
        };

        const onError = (error) => {
            console.error(error);
            handleClose(shouldClose, { ...isPending, create: false });
        };

        createIdea(params, onSuccess, onError);
    };

    const resetReplacementMatrix = () => {
        const currentReplacement = getReplacement();

        const issuesToDelete = currentReplacement.issues.map((issue) => ({ id: issue.id })) || [];
        const params = {
            issues: issuesToDelete,
            ideaId: replacementId || '',
        };
        const onSuccess = () => {};
        const onError = (error) => {
            console.error(error);
        };

        params.ideaId && createIssuesWithMessages(params, onSuccess, onError);

        currentReplacement.potentialBenefitsOfReplacement.forEach((benefit) => {
            benefit.target.forEach(target => {
                if (isDefaultCategory(target)) return;
                
                removeCustomCategory(target);
            })
        });

        setReplacement({ ...initialState });
        updateUploadInfo({ ...initialUpload });
        setCurrentTab(0);
        setIsNew(!edit);
    };

    const updateValues = () => {
        updateReplacementDetails(false);
    };

    const updateReplacementDetails = () => {
        const currentReplacement = replacementMatrix;

        if (Object.keys(currentReplacement).length) {
            const benefitsOfReplacement = currentReplacement.potentialBenefitsOfReplacement;
            const hasBenefitsOfReplacement = benefitsOfReplacement && benefitsOfReplacement.length > 0;
            const issues = currentReplacement.issues;
            const hasIssues = issues && issues.length > 0;
            const issuesMessages = [
                ...messages,
                ...(currentReplacement.issuesMessages ? currentReplacement.issuesMessages : []),
            ];
            const hasIssuesMessages = issuesMessages && issuesMessages.length > 0;
            const image = currentReplacement.image;
            const currentRating =
                currentReplacement.rating === null
                    ? null
                    : currentReplacement.rating === 0
                    ? 0
                    : currentReplacement.rating;
            const currentFeasibleRating =
                currentReplacement.feasibleRating === null
                    ? null
                    : currentReplacement.feasibleRating === 0
                    ? 0
                    : currentReplacement.feasibleRating;

            const params = {
                existingReplacement: currentReplacement.existingReplacement,
                isIdeaNew: currentReplacement.potentialBenefits,
                isIdeaFeasible: currentReplacement.typeReplacement,
                score: currentRating,
                feasibleScore: currentFeasibleRating,
                potentialBenefitsOfReplacement: (hasBenefitsOfReplacement && benefitsOfReplacement) || [
                    { name: '', description: '', target: [], userInfo: '' },
                ],
                issues: (hasIssues && issues) || [{ comment: '', description: '', createdByUser: '' }],
                issuesMessages: (hasIssuesMessages && issuesMessages) || [],
                ideaName: currentReplacement.replacementName || '',
                comment: currentReplacement.comments || '',
                image: {...image} || initialState.image,
                imagesLibrary: currentReplacement.imagesLibrary || initialState.imagesLibrary,
                removeBenefit: currentReplacement.removeBenefit,
            };

            if (image && image.name) {
                updateUploadInfo({
                    ...initialUpload,
                    name: image.originalName,
                    date: image.date,
                    size: image.size,
                    uploadName: image.name,
                    uploaded: true,
                    id: image.id,
                });
            };

            const updatedDependency = { ...getReplacement(), ...params };
            handleSetReplacement(updatedDependency);
            setSavedReplacement({ ...updatedDependency });
        } else {
            handleSetReplacement({ ...initialState });
            setSavedReplacement({ ...initialState });
        }
    };

    const updateReplacement = (shouldClose = false) => {
        if (!replacementId) {
            return;
        }

        shouldClose && setIsClosing(true);

        const params = {
            id: replacementId,
            idRemoteComponent: remoteComponent.id,
            customCategories: getCustomCategories(),
            productId: productId,
            teamId: teamId || '',
            ...getReplacementDetails(false),
        };

        params.imagesLibrary = params.imagesLibrary.map((image) => {
            const imageCopy = {...image};
            delete imageCopy.imageSource;
            return imageCopy;
        })

        delete params.image.imageSource;
        
        setIsPending({ ...isPending, update: true });
        const onSuccess = () => {
            handleClose(shouldClose, { ...isPending, update: false });
            setIsLoadingImage(false);
        };

        const onError = (error) => {
            console.error(error);
            handleClose(shouldClose, { ...isPending, update: false });
        };

        const issues = getIssuesWithMessages() || [];

        const issuesParams = {
            ideaId: replacementId || '',
            issues,
        };

        const onSuccessUpdateIssues = () => {};

        const onErrorUpdateIssues = (error) => {
            console.error(error);
        };

        if (shouldClose && !isPending.update) {
            createIssuesWithMessages(issuesParams, onSuccessUpdateIssues, onErrorUpdateIssues);
        }

        updateIdea(params, onSuccess, onError);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        handleSetReplacement({ ...getReplacement(), [name]: value });
    };

    const handleAddIssue = () => {
        const currentDependency = getReplacement();

        const lastIssue = currentDependency?.issues[currentDependency?.issues?.length - 1];

        if (lastIssue?.comment || currentDependency?.issues?.length === 0) {
            handleSetReplacement({ ...currentDependency, issues: [...currentDependency.issues, { comment: '', description: '', createdByUser: '' }] });
        }
    };

    const handleRemoveIssue = (index) => {
        const currentDependency = getReplacement();
        const filteredIssues = currentDependency.issues.filter((issue, issueIndex) => issueIndex !== index);
        const filteredMessages = currentDependency.issuesMessages.map((message) => {
            if (message.issueIndex > index) {
                return { ...message, issueIndex: --message.issueIndex };
            } else if (message.issueIndex === index) {
                return {};
            }
            return message;
        });
        handleSetReplacement({
            ...currentDependency,
            issues: [...filteredIssues],
            issuesMessages: [...filteredMessages],
            deletedIssues: [
                ...(currentDependency.deletedIssues ? currentDependency.deletedIssues : []),
                { id: currentDependency.issues[index].id },
            ],
        });
    };

    const selectCurrentReplacementImageFromLibrary = (index) => {
        const currentReplacement = getReplacement();
        handleSetReplacement({ ...currentReplacement, image: currentReplacement.imagesLibrary[index] });
    }
    const removeImageFromLibrary = (id) => {
        const currentReplacement = getReplacement();
        const imagesLibrary = [...currentReplacement.imagesLibrary];
        const indexOfSelectedImage = imagesLibrary.findIndex((image) => image.id === id);
        
        if (id===currentReplacement.image.id) {
            const newCurrentImageIndex=indexOfSelectedImage ? imagesLibrary.length % indexOfSelectedImage: imagesLibrary.length-1;
            
            handleSetReplacement({ 
                ...currentReplacement,
                image: imagesLibrary.length - 1 ? imagesLibrary[newCurrentImageIndex] : initialState.image,
                imagesLibrary: imagesLibrary.filter((image) => image.id !== id),
            });

            return newCurrentImageIndex;
        }
        handleSetReplacement({ ...currentReplacement, imagesLibrary: imagesLibrary.filter((image) => image.id!==id) });
        return imagesLibrary.findIndex((image) => image.id === currentReplacement.image.id);
    }

    const removeLastIssue = (index, idIssues) => {
        const currentReplacement = getReplacement();
        const foundIssue = currentReplacement.issues.find((issue, issueIndex) => issueIndex === index);

        let filteredMessages = [];

        if (idIssues) {
            filteredMessages = currentReplacement.issuesMessages.filter((message) => idIssues !== message.issueId);
        } else {
            filteredMessages = currentReplacement.issuesMessages.filter((message) => message.issueIndex !== index);
        };

        foundIssue.comment = '';
        foundIssue.description = '';
        foundIssue.createdByUser = '';

        handleSetReplacement({
            ...currentReplacement,
            issues: [...currentReplacement.issues],
            issuesMessages: [...filteredMessages],
            deletedIssues: [
                ...(currentReplacement.deletedIssues ? currentReplacement.deletedIssues : []),
                { id: currentReplacement.issues[index].id },
            ],
        });
    };

    const removeMessage = (indexMessage, idSavedMessage) => {
        const currentReplacement = getReplacement();
        let filteredMessages = [];

        if (idSavedMessage) {
            filteredMessages = currentReplacement.issuesMessages.filter((message) => idSavedMessage !== message._id);
        } else {
            filteredMessages = currentReplacement.issuesMessages.filter((message, index) => index !== indexMessage);
        };

        handleSetReplacement({
            ...currentReplacement,
            issuesMessages: [...filteredMessages],
        });
    };
    
    const removeLastMessage = (indexMessage, issueId, idSavedMessage) => {
        const currentReplacement = getReplacement();
        let foundMessage = {};

        if (idSavedMessage) {
            foundMessage = currentReplacement.issuesMessages.find((message) => idSavedMessage === message._id);
        } else {
            foundMessage = currentReplacement.issuesMessages.find((message, index) => indexMessage === index && issueId === message.issueIndex);
        };

        foundMessage.message = '';

        handleSetReplacement({
            ...currentReplacement, 
            issuesMessages: [
                ...currentReplacement.issuesMessages
            ]
        });
    };

    const handleIssueChange = (index, value, name) => {
        const currentDependency = { ...getReplacement() };
        const changedIssues = [...currentDependency.issues];
        if (name === "comment") {
            changedIssues[index].comment = value;
            changedIssues[index].createdByUser = `${props.userFullName.name} ${props.userFullName.lastName}`;
        };

        if (name === "description") {
            changedIssues[index].description = value;
        };

        handleSetReplacement({ ...currentDependency, issues: [...changedIssues] });
    };

    const handleAddIssueMessage = (issueId, issueIndex) => {
        const currentDependency = getReplacement();
        const filteredMessages =
            currentDependency?.issuesMessages.filter((message) =>
                message.issueId ? message.issueId === issueId : message.issueIndex === issueIndex
            ) || [];

        const lastMessage = filteredMessages[filteredMessages.length - 1];
        const canBeCreated = !lastMessage || lastMessage.message !== '';

        if (canBeCreated) {
            handleSetReplacement({
                ...currentDependency,
                issuesMessages: [...currentDependency.issuesMessages, { message: '', issueId, issueIndex }],
            });
        }
    };

    const handleIssueMessageChange = (value, index, userId, issueIndex) => {
        const currentReplacement = getReplacement();
        const changedIssuesMessages = [...currentReplacement.issuesMessages];
        changedIssuesMessages[index] = { message: value, userId, issueIndex }; 
        handleSetReplacement({ ...currentReplacement, issuesMessages: changedIssuesMessages });
    };

    const updateBenefit = (name, index, replacement, value) => {
        const changedBenefits = [...replacement.potentialBenefitsOfReplacement];
        const newBenefit = { ...changedBenefits[index] };
        newBenefit[name] = value;
        changedBenefits[index] = newBenefit;
        handleSetReplacement({ ...replacement, potentialBenefitsOfReplacement: changedBenefits });
    };

    const handleTargetChange = (index, { id, name }) => {
        const currentReplacement = getReplacement();
        
        const updatedTarget = [...currentReplacement.potentialBenefitsOfReplacement[index].target];
        const indexUpdatedTarget = updatedTarget.findIndex(target => target.name === name)

        if (indexUpdatedTarget === -1) {
            updatedTarget.push({ id, name });
        } else {
            updatedTarget.splice(indexUpdatedTarget, 1);
        }

        updateBenefit("target", index, currentReplacement, updatedTarget);
    };

    const handleBenefitChange = (index, value, name) => {
        const currentReplacement = getReplacement();
        updateBenefit(name, index, currentReplacement, value);
    }

    const handleDeleteOption = (changedLabel) => {
        const currentReplacement = getReplacement();
        
        currentReplacement.potentialBenefitsOfReplacement.forEach(benefit => {
            const updatedTargets = benefit.target.filter((targetElement) => targetElement.name !== changedLabel);
            benefit.target = updatedTargets
        });

        handleSetReplacement({
            ...currentReplacement,
            potentialBenefitsOfReplacement: currentReplacement.potentialBenefitsOfReplacement
        });
        
        removeCustomCategory(changedLabel);
    }

    const addBenefit = () => {
        const currentReplacement = getReplacement();
        handleSetReplacement({
            ...currentReplacement,
            potentialBenefitsOfReplacement: [
                ...currentReplacement.potentialBenefitsOfReplacement,
                { name: '', description: '', target: [], userInfo: '' },
            ],
        });
    };

    const removeBenefit = (index) => {
        const currentReplacement = getReplacement();
        removeCustomCategory(currentReplacement.potentialBenefitsOfReplacement[index].target);
        const filteredBenefits = currentReplacement.potentialBenefitsOfReplacement.filter(
            (benefit, benefitIndex) => benefitIndex !== index
        );
        handleSetReplacement({ ...currentReplacement, potentialBenefitsOfReplacement: [...filteredBenefits] });
    };

    const removeLastBenefit = (index) => {
        const currentReplacement = getReplacement();
        removeCustomCategory(currentReplacement.potentialBenefitsOfReplacement[index].target);
        const foundBenefit = currentReplacement.potentialBenefitsOfReplacement.find((benefit, benefitIndex) => benefitIndex === index);

        foundBenefit.name = '';
        foundBenefit.description = '';
        foundBenefit.target = [];
        foundBenefit.userInfo = '';

        handleSetReplacement({
            ...currentReplacement, 
            potentialBenefitsOfReplacement: [
                ...currentReplacement.potentialBenefitsOfReplacement
            ]
        });
    };

    const replacementComponents = {
        firstComponent: projectName || '',
        secondComponent: remoteComponent.name || '',
    };

    const handleSetCurrentTab = (value) => {
        setCurrentTab(value);
        handleSave();
    };

    const handleSave = () => {
        const upToDate = compareReplacements(savedReplacement, replacement);

        if (!upToDate) {
            const updatedReplacement = { ...getReplacement() };

            setSavedReplacement(updatedReplacement);
            isNew ? createReplacement() : updateReplacement();
        }
    };

    const compareReplacements = (firstItem, secondItem) => {
        const keys = Object.keys(firstItem);
        let isEqual = true;

        for (let key of keys) {
            if (key === 'potentialBenefitsOfReplacement') {
                const firstBenefit =
                    firstItem[key] && firstItem[key].filter((benefit) => benefit.name && benefit.target && benefit.description && benefit.userInfo);
                const secondBenefit =
                    secondItem[key] && secondItem[key].filter((benefit) => benefit.name && benefit.target && benefit.description && benefit.userInfo);

                if (firstBenefit.length !== secondBenefit.length) {
                    isEqual = false;
                    break;
                }

                for (let benefit of firstBenefit) {
                    const foundBenefit = secondBenefit.find(
                        (item) => item.name === benefit.name && item.target === benefit.target && item.description === benefit.description && item.userInfo === benefit.userInfo
                    );
                    if (!foundBenefit) {
                        isEqual = false;
                        break;
                    }
                }

                continue;
            }

            if (key === 'issues') {
                if (firstItem[key].join('') !== secondItem[key].join('')) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (key === 'issuesMessages') {
                if (firstItem[key].join('') !== secondItem[key].join('')) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (key === 'image') {
                if (firstItem[key].name !== secondItem[key].name) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (firstItem[key] !== secondItem[key]) {
                isEqual = false;
                break;
            }
        }

        return isEqual;
    };

    const prevEnabled = () => {
        if (currentTab === 0) {
            return false;
        }

        return true;
    };

    const nextEnabled = () => {
        if (currentTab === 2) {
            return false;
        }

        return true;
    };

    const isCompleted = () => {
        const currentReplacement = getReplacement();
        return (
            currentReplacement.existingReplacement === true ||
            currentReplacement.isIdeaNew === false ||
            currentReplacement.isIdeaFeasible === false ||
            (currentReplacement.isIdeaFeasible === true && currentReplacement.score > 0)
        );
    };

    const handleSetReplacement = (value) => {
        setReplacement(value);
    };

    const getReplacement = () => {
        return {...replacement, image: {...replacement.image}};
    };

    const handleImageUpload = (file, imageSource = null) => {
        const CancelToken = axios.CancelToken;

        const formData = new FormData();
        formData.append('image', file, file.name);
        updateUploadInfo({ progress: 0, cancel: null, uploaded: false, name: file.name, size: file.size });

        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                updateUploadInfo({ progress: completed });
            },
            cancelToken: new CancelToken(function executor(c) {
                updateUploadInfo({ cancel: c });
            }),
        };

        const onSuccess = (response) => {
            updateUploadInfo({ uploaded: true, date: new Date(), uploadName: response.filename, id: response.imageId });
            const image = {
                name: response.filename,
                originalName: file.name,
                id: response.imageId,
                imageSource
            };

            const currentReplacement = getReplacement();
            if (!currentReplacement.imagesLibrary.length) {
                handleSetReplacement({ ...currentReplacement, imagesLibrary:[...currentReplacement.imagesLibrary, image], image });
                return;
            }
            handleSetReplacement({ ...currentReplacement, imagesLibrary:[...currentReplacement.imagesLibrary, image] });
        };

        const onError = (error) => {
            console.error(error);
            if (uploadInfo.name) {
                updateUploadInfo({ ...initialUpload });
            }
        };

        setIsLoadingImage(true);
        uploadImage(formData, config, onSuccess, onError);
    };

    const handleCancelClick = () => {
        setNewGeneratedImage({
            isNew: false,
            urlImage: ""
        });
        uploadInfo.cancel && uploadInfo.cancel('Canceled by user');
        updateUploadInfo({ ...initialUpload });

        const currentReplacement = getReplacement();
        handleSetReplacement({ ...currentReplacement, image: initialState.image });
    };

    const updateUploadInfo = (value) => {
        if (value) {
            setUploadInfo((state) => ({ ...state, ...value }));
        } else {
            setUploadInfo((state) => ({ ...state, ...initialUpload }));
        }
    };

    const currentUploadInfo = uploadInfo;

    const handleCloseDialog = (shouldClose) => {
        setIsClosing(true);
        const upload = uploadInfo;
        if (upload.name && !upload.uploaded) {
            upload.cancel && upload.cancel('Canceled by user');
        }

        setUploadInfo({ ...initialUpload });
        closeDialog && closeDialog(shouldClose);
    };

    const matrixBodyClass = [1, 2].includes(currentTab) ? ' benefits-container' : '';

    const addCustomCategory = (name) => {
        const foundCategory = customCategories.find((category) => category.name === name);
        if (foundCategory) {
            foundCategory.count++;
            return;
        }

        const currentId = customCategories[customCategories.length - 1]?.id;

        setCustomCategories([...customCategories, {
            id: _.isNumber(currentId) ? currentId + 1 : 3,
            name, 
            count: 1
        }]);
    };

    const removeCustomCategory = (name) => {
        const updatedCategories = customCategories.filter((category) => category.name !== name);
        setCustomCategories(updatedCategories);
    };

    const renameCustomCategory = (oldName, newName) => {
        const foundCategoryIndex = customCategories.findIndex((category) => category.name === newName);
        if (foundCategoryIndex !== -1) {
            customCategories[foundCategoryIndex].count++;

            return;
        }

        const replacement = getReplacement();
        
        replacement.potentialBenefitsOfReplacement.forEach(benefit => {
            const currentTargetIndex = benefit.target.findIndex(target => target.name === oldName);
            
            if (currentTargetIndex !== -1) {
                benefit.target.splice(currentTargetIndex, 1, { id: benefit.target[currentTargetIndex].id, name: newName });
            }
        }) 

        handleSetReplacement({
            ...replacement,
            potentialBenefitsOfReplacement: replacement.potentialBenefitsOfReplacement
        })
            
        const copiedArray = [...customCategories];
        const findOldCategoryIndex = customCategories.findIndex((category) => category.name === oldName);

        const { count, id } = customCategories[findOldCategoryIndex];

        copiedArray.splice(findOldCategoryIndex, 1, { id, count, name: newName });
        setCustomCategories(copiedArray);
    }

    const getCustomCategories = () => {
        return customCategories.filter((category) => category.count > 0);
    };

    const addIdeasName = (ideasName) => {
        setReplacement({...replacement, ideaName: ideasName})
        setOpenGenerateModal(false);
        setSuggestedOptions([]);
    };

    const generateNames = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
             if (code === "30840" || code === "30841") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== "30841");
                return;
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentReplacement = getReplacement();
        const description = currentReplacement.comment ? currentReplacement.comment : descriptionOfIdea;

        getGeneratedNamesForIdea(
            {
                projectName: productName ? productName : projectName,
                productType,
                projectId: productId,
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const closeErrorWindow = () => {
        setError({
            openErrorModal: false,
            message: '',
            headerError: ''
        });
    };

    const handleChangeScore = (e) => {
        setReplacement({...replacement, score: e });
    };

    const handleChangeFeasibleScore = (e) => {
        setReplacement({...replacement, isIdeaFeasible: e > 0, feasibleScore: e });
    };

    const selectGenerateOption = (nameOption) => {
        setSelectedGenerateOptions((prev) => {
            const prevSelectedOption = new Set(prev);
            const key = `${nameOption}`;
            prev.has(key) ? prevSelectedOption.delete(key) : prevSelectedOption.add(key);
            return prevSelectedOption;
        });
    };

    const addGenerateBenefits = () => {
        const benefits = [];

        suggestedOptions.map(benefit => {
            if (selectedGenerateOptions.has(`${benefit}`)) {
                const params = {
                    name: benefit.includes("：") ? benefit.split('：')[0] : benefit.split(':')[0],
                    description: benefit.includes("：") ? benefit.split('：')[1] : benefit.split(':')[1],
                    target: [],
                    userInfo: "AI"
                }
                benefits.push(params);
            }
        })

        setReplacement({
            ...replacement,
            potentialBenefitsOfReplacement:[
                ...benefits,
                ...replacement.potentialBenefitsOfReplacement,
            ],
        });

        setOpenGenerateModal(false);
        setSelectedGenerateOptions(new Set());
        setSuggestedOptions([]);
    };

    const generateBenefits = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
             if (code === "30840" || code === "30841") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== "30841");
                return;
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentSubtraction = getReplacement();
        const description = currentSubtraction.comment ? currentSubtraction.comment : descriptionOfIdea;

        getGeneratedBenefits(
            {
                projectName: productName ? productName : projectName,
                projectId: productId,
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const closeGenerateModal = () => {
        setOpenGenerateModal(false);
        setSuggestedOptions([]);
    };

    const handleRightClick = () => {
        if (currentTab === 1 
            && (replacement.score === null || replacement.score === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_IMPACT"),
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        handleSetCurrentTab(currentTab + 1);;
    };

    const handleLeftClick = () => {
        handleSetCurrentTab(currentTab - 1);
    };

    const getMessageForCheckRating = () => {
        return (
            <p className="flex-column mt-25px">
                <span style={{ margin: "0" }}>{getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_IMPACT")}</span>
                <span>{getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_FEASIBILITY")}</span>
            </p>
        );
    };

    const checkRating = () => {
        if ((currentTab === 2) 
            && (replacement.score === null || replacement.score === undefined)
            && (replacement.feasibleScore === null || replacement.feasibleScore === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getMessageForCheckRating(),
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        if ((currentTab === 2) 
            && (replacement.score)
            && (replacement.feasibleScore === null || replacement.feasibleScore === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_FEASIBILITY"),
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        if ((currentTab === 2) 
            && (replacement.feasibleScore)
            && (replacement.score === null || replacement.score === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_IMPACT"),
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        isNew ? createReplacement(true) : updateReplacement(true);
    };

    const changeCurrentTab = (index) => {
        setCurrentTab(index);
    };

    const generateChallenges = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
             if (code === "30840" || code === "30841") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== "30841");
                return;
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentDependency = getReplacement();
        const description = currentDependency.comment ? currentDependency.comment : descriptionOfIdea;

        getGeneratedChallenges(
            {
                projectName: productName ? productName : projectName,
                projectId: productId,
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const addGenerateChallenges = () => {
        const generatedIssues = [];

        suggestedOptions.map(issue => {
            if (selectedGenerateOptions.has(`${issue}`)) {
                const params = {
                    comment: issue.includes("：") ? issue.split('：')[0] : issue.split(':')[0],
                    description: issue.includes("：") ? issue.split('：')[1] : issue.split(':')[1],
                    createdByUser: "AI"
                }
                generatedIssues.push(params);
            }
        })

        setReplacement({
            ...replacement,
            issues:[
                ...generatedIssues,
                ...replacement.issues,
            ],
        });

        setOpenGenerateModal(false);
        setSelectedGenerateOptions(new Set());
        setSuggestedOptions([]);
    };

    const convertImage = async (imageBase64) => {
        try {
            const base64Response = await fetch(`data:image/png;base64,${imageBase64}`);
            const blobImage = await base64Response.blob();

            let dt = new DataTransfer();
            dt.items.add(new File([blobImage], 'image.png', { type: blobImage.type }));
            const fileImage = dt.files[0];
            const bufferImage = await fileImage.arrayBuffer();
    
            const reader = new FileReader();
    
            reader.onload = () => {
                const imageUrl = `data:image/png;base64,${Buffer.from(bufferImage).toString('base64')}`;
                setPotentialGeneratedImageUrl(imageUrl);
            };
    
            reader.readAsArrayBuffer(fileImage);
    
            return fileImage;
        } catch (error) {
           setError({
                openErrorModal: true,
                message: error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
           }); 
        };
    };

    const handleAcceptPotentialImage = () => {
        handleImageUpload(potentialGeneratedImageFile, potentialGeneratedImageUrl)
        setIsOpenGenerateImageModal(false)
    }

    const generateImage = () => {
        setIsOpenGenerateImageModal(true);

        const onSuccess = async (imageBase64) => {
            setNewGeneratedImage({
                isNew: true,
                urlImage: `data:image/png;base64,${imageBase64}`
            });
            const fileImage = await convertImage(imageBase64);

            setPotentialGeneratedImageFile(fileImage);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
             if (code === "30840" || code === "30841") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== "30841");
                return;
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentReplacement = getReplacement();
        const description = currentReplacement.comment ? currentReplacement.comment : descriptionOfIdea;

        getGeneratedImage(
            {
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const header = {
        projectName: project.product.name,
        productName,
        firstComponent: replacementComponents.secondComponent,
        replacingComponent: replacingComponent?.name,
    };

    const getReplacementHeader = () => {
        return (
            <p className='replacement-dialog-header-imagine'> 
                {getTranslation('SUBTRACTION_DESCRIPTION_IMAGINE_A')}
                <u>{header.productName ? header.productName : header.projectName}</u>
                {getTranslation('DESCRIBE_NEW_DEPENDENCY_WHERE_THE')}
                <u>{header.firstComponent}</u>
                {productType === 'process' && 
                    <span style={{marginRight: '10px'}}>{getTranslation('SUBTRACTION_MODAL_HEADER_STEP')}</span>
                }
                {getTranslation('REPLACEMENT_IS_REPlACED')}
                <u>{header.replacingComponent}</u>
                {productType === 'process' && 
                    <span>{getTranslation('SUBTRACTION_MODAL_HEADER_STEP')}</span>
                }
            </p>
        );
    };

    const descriptionOfIdea = `
        ${productName ? productName : project.product.name}
        ${getTranslation('DESCRIBE_NEW_DEPENDENCY_WHERE_THE')}
        ${replacementComponents.secondComponent}
        ${productType === 'process' ? getTranslation('SUBTRACTION_MODAL_HEADER_STEP') : ""}
        ${getTranslation('REPLACEMENT_IS_REPlACED')}
        ${replacingComponent?.name}
        ${productType === 'process' ? getTranslation('SUBTRACTION_MODAL_HEADER_STEP') : ""}
    `.replace(/[\s]+/gi, ' ');

    return (
        <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false}>
            <div className='subtraction-dialog-wrapper'>
                <ReplacementModalHeader
                    closeDialog={handleCloseDialog}
                    replacement={getReplacement()}
                    currentTab={currentTab}
                    setCurrentTab={handleSetCurrentTab}
                    isCompletedIdea={isCompletedIdea}
                    productType={productType}
                    getReplacementHeader={getReplacementHeader}
                    changeCurrentTab={changeCurrentTab}
                />

                <div className={'subtraction-dialog-body flex-column' + matrixBodyClass}>
                    <ReplacementModalTabs
                        selectCurrentReplacementImageFromLibrary={selectCurrentReplacementImageFromLibrary}
                        removeImageFromLibrary={removeImageFromLibrary}
                        handleAcceptPotentialImage={handleAcceptPotentialImage}
                        potentialGeneratedImageUrl={potentialGeneratedImageUrl}                    
                        currentTab={currentTab}
                        replacement={getReplacement()}
                        setReplacement={handleSetReplacement}
                        handleChange={handleChange}
                        userFullName={props.userFullName}
                        userId={props.userId}
                        addBenefit={addBenefit}
                        removeBenefit={removeBenefit}
                        removeLastBenefit={removeLastBenefit}
                        handleBenefitChange={handleBenefitChange}
                        handleTargetChange={handleTargetChange}
                        addIssue={handleAddIssue}
                        removeIssue={handleRemoveIssue}
                        removeLastIssue={removeLastIssue}
                        removeMessage={removeMessage}
                        removeLastMessage={removeLastMessage}
                        handleIssueChange={handleIssueChange}
                        uploadInfo={currentUploadInfo}
                        newGeneratedImage={newGeneratedImage}
                        handleCancelClick={handleCancelClick}
                        handleFileSelect={handleImageUpload}
                        customCategories={getCustomCategories()}
                        isLoadingImage={isLoadingImage}
                        addIssueMessage={handleAddIssueMessage}
                        handleIssueMessageChange={handleIssueMessageChange}
                        productType={productType}
                        header={header}
                        handleGenerateNames={generateNames}
                        suggestedLoading={suggestedLoading}
                        addIdeasName={addIdeasName}
                        handleChangeScore={handleChangeScore}
                        handleChangeFeasibleScore={handleChangeFeasibleScore}
                        suggestedOptions={suggestedOptions}                        
                        openGenerateModal={openGenerateModal}
                        closeGenerateModal={closeGenerateModal}
                        selectGenerateOption={selectGenerateOption}
                        selectedGenerateOptions={selectedGenerateOptions}
                        addGenerateBenefits={addGenerateBenefits}
                        handleGenerateBenefits={generateBenefits}
                        handleGenerateChallenges={generateChallenges}
                        addGenerateChallenges={addGenerateChallenges}
                        handleGenerateImage={generateImage}
                        isOpenGenerateImageModal={isOpenGenerateImageModal}
                        closeDialogGenerateImage={() => setIsOpenGenerateImageModal(false)}
                        generatedImage={generatedImage}
                        handleDeleteOption={handleDeleteOption}
                        handleRenameOption={renameCustomCategory}
                        addCustomCategory={addCustomCategory}
                    />
                </div>

                <ReplacementModalFooter
                    resetReplacement={resetReplacementMatrix}
                    prevEnabled={prevEnabled}
                    nextEnabled={nextEnabled}
                    isCompleted={isCompleted}
                    handleRightClick={handleRightClick}
                    handleLeftClick={handleLeftClick}
                    disabledNextButton={disabledNextButton}
                    checkRating={checkRating}
                    currentTab={currentTab}
                />

                {error.openErrorModal &&
                    <ErrorMessageModal 
                        message={error.message}
                        closeModalWindow={closeErrorWindow}
                        textButton={getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
                        handleButton={closeErrorWindow}
                        header={error.headerError}
                    />
                }

                {paymentInfo.key && (
                    <CheckoutModal
                        isOpenAISubscription
                        closeDialog={() => setPaymentInfo((prev) => ({ ...prev, key: '' }))}
                        subscriptionInfo={paymentInfo}
                        history={history}
                        mode='payment'
                        setGeneratedOpenAIRequests={setGeneratedOpenAIRequests}
                    />
                )}

                {isOpenBuyCreditsgModal && (
                    <BuyMoreAICreditsModal
                        setPaymentInfo={setPaymentInfo}
                        closeDialog={() => setIsOpenBuyCreditsgModal(false)}
                    />
                )}

                {isOpenWarningModal && (
                    <WarningNotTokensModal
                        closeDialog={() => {
                            setIsOpenWarningModal(false);
                        }}
                        openBuyModal={() => setIsOpenBuyCreditsgModal(true)}
                        history={history}
                        isEnoghTokensForImage={isEnoghTokensForImage}
                    />
                )}
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    language: state.auth.userInfo.language,
});

const mapDispatchToProps = {
    setGeneratedOpenAIRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplacementEditModal);
