import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import './styles.css';

import { getProjectViewInfo } from '../../actions/projectActions';

import MessageShowNotFound from '../common/MessageShowNotFound';
import ProjectTopBar from '../common/ProjectTopBar';
import DependencyMatrixTable from '../DependencyMatrix/DependencyMatrixTable';
import { sortByName } from '../common/utils/sort';
import DependencyMatrixHeader from '../DependencyMatrix/DependencyMatrixHeader';
import BlueHeader from '../common/BlueHeader';
import { getTranslation } from '../../helpers/getLanguage';

const toggleLabels = ['TOGGLE_LABELS_ADVANCED', 'TOGGLE_LABELS_BINARY'];

const initialFiltersState = {
    filterBy: '',
    ideas: '',
    search: '',
};

const initialColumnBands = [{ title: '', children: [{ name: '', attributeId: '', componentId: '' }] }];

const PublicMatrixView = ({
    attributes,
    components,
    detailsMatrix,
    errors,
    match,
    getProjectViewInfo,
    setPreference,
    history,
    product,
    resetProjectInfo,
    ...props
}) => {
    const { projectId } = match.params;

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnBands, setColumnBands] = useState(initialColumnBands);
    const [filters, setFilters] = useState(initialFiltersState);

    useEffect(() => {
        getProjectViewInfo(projectId);
    }, [projectId, getProjectViewInfo]);

    const emptyHistory = {
        push: () => {}
    }

    useEffect(() => {
        if ((attributes.length > 0 && detailsMatrix.length > 0, components.length > 0)) {
            const sortComponents = components.slice().sort((a, b) => {
                if (a.locked) {
                    return -1;
                } else if (b.locked) {
                    return 1;
                }
                return sortByName(a, b);
            });

            const sortAttributes = attributes.slice().sort(sortByName);

            const newSortAttributes = [];

            sortComponents.forEach((component) => {
                const filteredAttributes = sortAttributes.filter((attribute) => attribute.componentId === component.id);

                for (const attribute of filteredAttributes) {
                    newSortAttributes.push(attribute);
                }
            });

            const columnMatrix = newSortAttributes
                .filter((attribute) => attribute.internal)
                .map((attribute) => {
                    const dataAttribute = attribute;
                    return { name: dataAttribute.id, title: dataAttribute.name };
                });

            const bands = sortComponents
                .map((component) => {
                    const newComponent = { ...component };
                    const childrenColumns = [];
                    newSortAttributes.forEach((attribute) => {
                        const newAttribute = { ...attribute };
                        if (newAttribute.componentId === newComponent.id) {
                            childrenColumns.push({
                                name: newAttribute.name,
                                attributeId: newAttribute.id,
                                componentId: newAttribute.componentId,
                            });
                        }
                    });
                    if (childrenColumns) {
                        return {
                            title: newComponent.name,
                            children: childrenColumns,
                            internal: newComponent.internal,
                            componentId: newComponent.id,
                        };
                    }
                    return null;
                })
                .filter((band) => band && band.children.length);

            const rowsMatrix = newSortAttributes.map((attributeRow) => {
                const dataAttribute = attributeRow;
                const generatedEmptyRows = [];
                newSortAttributes.forEach((attributeCol) => {
                    let status = 0;
                    const currentDetails = detailsMatrix.find(
                        (detail) =>
                            (detail.idAttributeRow === attributeRow.id && detail.idAttributeCol === attributeCol.id) ||
                            (detail.idAttributeRow === attributeCol.id && detail.idAttributeCol === attributeRow.id)
                    );
                    if (currentDetails) {
                        status = currentDetails.status;
                    }
                    generatedEmptyRows[attributeCol.id] = attributeRow.id !== attributeCol.id ? status : '';
                });
                const currentComponent = components.find((elem) => elem.id === dataAttribute.componentId);
                let component = {};
                if (currentComponent) {
                    component = {
                        components: currentComponent.name,
                        internal: currentComponent.internal,
                        componentId: currentComponent.id,
                        locked: currentComponent.locked,
                    };
                }
                return { ...component, attributes: dataAttribute.name, ...generatedEmptyRows };
            });

            const groupRows = _.groupBy(rowsMatrix, 'componentId');
            const finishRows = Object.values(groupRows).map((row) => {
                const newRow = _.cloneDeep(row);
                const rowObj = newRow.map((elem, index) => {
                    const newElem = { ...elem };
                    newElem.rowSpan = index === 0 ? newRow.length : 0;
                    return newElem;
                });
                return rowObj;
            });

            const result = finishRows.reduce((acc, val) => acc.concat(val), []);
            setColumns([
                { name: 'components', title: ' ' },
                { name: 'attributes', title: ' ' },
                { name: 'rowSpan', title: 'rowSpan' },
                ...columnMatrix,
            ]);
            setColumnBands(bands);
            setRows([...result.filter((row) => row.internal), ...result.filter((row) => !row.internal)]);
        } else {
            setColumns([]);
            setColumnBands(initialColumnBands);
            setRows([]);
        }
    }, [attributes, detailsMatrix, components]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleInputChange = (e) => {
        const { name, value } = e;
        setFilters({ ...filters, [name]: value });
    };

    const showNotFoundError = errors && errors.response && errors.response.status === 404;

    return (
        <div>
            <BlueHeader headerText={getTranslation('DEPENDENCY_MATRIX_TITLE')} />
            <ProjectTopBar match={match} history={emptyHistory} currentProjectName={product.name} isPublicView />
            <div className='dependency-matrix-wrapper view-dependency-matrix-wrapper'>
                {!errors && (
                    <>
                        <DependencyMatrixHeader
                            filters={filters}
                            setFilters={setFilters}
                            binaryMode={props.binaryMode}
                            setBinaryMode={(e) => setPreference({ matrixBinaryMode: e })}
                            handleChange={handleChange}
                            handleInputChange={handleInputChange}
                            labels={toggleLabels}
                            isPublicView={true}
                        />

                        <DependencyMatrixTable
                            rows={rows}
                            columns={columns}
                            handleOpenDetails={() => {}}
                            columnBands={columnBands}
                            detailsMatrix={detailsMatrix}
                            filters={filters}
                            binaryMode={props.binaryMode}
                            redirectToComponents={() => {}}
                            isLoading={props.isLoading}
                            history={emptyHistory}
                            projectId={projectId}
                            language={props.language}
                        />
                    </>
                )}

                {showNotFoundError && <MessageShowNotFound history={emptyHistory} />}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    language: state.auth.userInfo.language,
    product: state.project.product,
    attributes: state.project.attributes,
    components: state.project.components,
    detailsMatrix: state.project.detailsMatrix,
    isLoading: state.project.isLoading,
    errors: state.project.errors,
    binaryMode: state.preferences.matrixBinaryMode,
});

export default connect(mapStateToProps, { getProjectViewInfo })(PublicMatrixView);

