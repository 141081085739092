import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';
import { REACT_APP_AUTH0_DOMAIN } from '../common/config';

const baseUrl = `${baseApiUrl}/auth`;

export const recoverPassword = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/reset-password-request`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const changePassword = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/reset-password`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const registerUser = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/register`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const loginUser = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/login`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const auth0Login = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/auth0`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
}

export const samlLogin = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/saml-auth`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
}

export const samlLogout = async () => {
    axios.get(`https://${REACT_APP_AUTH0_DOMAIN}/v2/logout`);
};

export const verifyEmail = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/verify-email`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};
