import React, { useEffect, useState } from "react";
import { auth0Login } from "../../actions/authActions";
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const Auth0 = ({ auth0Login, auth, history }) => {
    const [redirectUrl, setRedirectUrl] = useState('');
    const location = useLocation();
    const token = new URLSearchParams(location.search).get("session_token");

    useEffect(() => {
        auth0Login(token);
    }, [])

    useEffect(() => {
        if (auth.isAuthenticated && !auth.status.premium) {
            const redirect = decodeURIComponent(redirectUrl.split('?redirect=')[1] || '');
            history.push(redirect || '/');
        }

        history.push('/');

    }, [auth, history, redirectUrl]);

    useEffect(() => {
        setRedirectUrl(location.search);
    }, [location.search]);


    return <></>
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToState = {
    auth0Login
};

export default connect(mapStateToProps, mapDispatchToState)(Auth0);