import React from 'react';
import CustomSwitch from '../inputs/CustomSwitch';
import CustomSearchInput from '../inputs/CustomSearchInput';
import CustomDropdown from '../inputs/CustomDropdown';
import DependencyOption from '../inputs/CustomDropdown/DependencyOption';
import BenefitOption from '../inputs/CustomDropdown/BenefitOption';
import ViewTutorialButtonWithModal from '../common/ViewTutorialButtonWithModal';

import Tooltip from '../common/Tooltip';
import { getTranslation } from '../../helpers/getLanguage';

const ideasDropdownOptions = [
    { value: '', label: 'IDEAS_DROPDOWN_OPTIONS_ALL_DEPENDENCIES' },
    { value: 0, label: 'IDEAS_DROPDOWN_OPTIONS_UNADDRESSED' },
    { value: 2, label: 'IDEAS_DROPDOWN_OPTIONS_NO_POTENTIAL' },
    { value: 1, label: 'IDEAS_DROPDOWN_OPTIONS_IN_PROGRESS' },
    { value: 3, label: 'IDEAS_DROPDOWN_OPTIONS_FEASIBLE_IDEAS' },
];

const options = [
    { value: '', label: 'OPTIONS_ALL_BENEFITS' },
    { value: 'Consumer', label: 'OPTIONS_CONSUMER_BENEFITS' },
    { value: 'Company', label: 'OPTIONS_COMPANY_BENEFITS' },
    { value: 'Other', label: 'OPTIONS_OTHER' },
];

const DependencyMatrixHeader = ({
    filters,
    handleChange,
    handleInputChange,
    labels,
    setFilters,
    binaryMode,
    setBinaryMode,
    isPublicView = false,
}) => {
    const tooltipMessage =
        getTranslation('TOOLTIP_MESSAGE_FORECASTING_MATRIX') +
        '\n\n' +
        getTranslation('TOOLTIP_MESSAGE_MATRIX_ELEMENT');

    return (
        <div className='flex-wrap justify-space-between'>
            {!isPublicView && (
                <div className='flex-column dependency-matrix-header-tutorial'>
                    <ViewTutorialButtonWithModal name='Matrix' className='mb-10' />
                    <Tooltip
                        message={tooltipMessage}
                        containerClass='dependency-matrix-title'
                        position='right'
                        innerText={getTranslation('PAGE_MATRIX_TITLE')}
                    />
                </div>
            )}

            <div className='flex-wrap align-center'>
                {!isPublicView && (
                    <div className='matrix-input-container flex align-center'>
                        <span className='input-label'>{getTranslation('FILTERED_BY')}</span>
                        <CustomDropdown
                            value={filters.filterBy}
                            handleChange={(e) => setFilters({ ...filters, filterBy: e })}
                            options={options}
                            OptionComponent={BenefitOption}
                        />
                    </div>
                )}
                <div className='matrix-input-container'>
                    <CustomDropdown
                        value={filters.ideas}
                        handleChange={(e) => handleInputChange({ name: 'ideas', value: e })}
                        options={ideasDropdownOptions}
                        OptionComponent={DependencyOption}
                    />
                </div>

                {!isPublicView && (
                    <div className='matrix-input-container flex align-center'>
                        <CustomSwitch value={binaryMode} setValue={setBinaryMode} labels={labels} />
                    </div>
                )}
                {!isPublicView && (
                    <div className='matrix-input-container'>
                        <CustomSearchInput
                            name='search'
                            placeholder={getTranslation('PAGE_MATRIX_SEARCH_DEPENDENCIES')}
                            value={filters.search}
                            onChange={handleChange}
                            containerClass='search-input-container'
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default DependencyMatrixHeader;
